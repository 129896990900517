// створює функції для відсліднковування змінної що містить кількість непрочитаних повідомлень
import React, { createContext, useContext, useState, useEffect } from 'react';
import state from '../../../state/state.js';

const MailContext = createContext();

export const MailProvider = ({ children }) => {
  const [unreadMailCount, setUnreadMailCount] = useState(state.unreadMailInProfile);

  useEffect(() => {
    const interval = setInterval(() => {
     
      setUnreadMailCount(state.unreadMailInProfile);
    }, 1000);
        return () => {clearInterval(interval);};
  }, [state.unreadMailInProfile]);
  
  useEffect(() => { }, [state.unreadMailInProfile]);
  
  return (
    <MailContext.Provider value={{ unreadMailCount }}>
      {children}
    </MailContext.Provider>
  );
};

export const useMailContext = () => useContext(MailContext);
