import React from 'react';
import './index.css';
import Modal from 'react-modal';
import reportWebVitals from './reportWebVitals';

import App from './App';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

const root = ReactDOM.createRoot(document.getElementById('root'));


Modal.setAppElement('#root'); // Де '#root' - це селектор вашого кореневого елемента.

root.render(

  // <React.StrictMode>
   <BrowserRouter>
  <App />
         
      </BrowserRouter>
    
  // </React.StrictMode>
);

reportWebVitals();




