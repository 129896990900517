import axios from "axios";
import state from "./state";

const indexedDBConnection = {};

// Відкриває базу даних IndexedDB
indexedDBConnection.openDatabase = () => {
    return new Promise((resolve, reject) => {
        const request = indexedDB.open('myDatabase', 10);

        request.onerror = (event) => {
            console.error('Помилка при відкритті IndexedDB:', {
                error: event.target.errorCode,
                message: 'Не вдалося відкрити IndexedDB'
            });
            reject(event.target.errorCode);
        };

        request.onsuccess = (event) => {
            resolve(event.target.result);
        };

        request.onupgradeneeded = (event) => {
            const db = event.target.result;

            // Створення об'єктів з магазинами даних
            const objectStores = [
                'athletes',
                'competition',
                'sportClubs',
                'mail',
                'archive',
                'userProfile'
            ];

            objectStores.forEach(storeName => {
                if (!db.objectStoreNames.contains(storeName)) {
                    db.createObjectStore(storeName, { keyPath: 'id' });
                    // console.info(`Об'єктний магазин '${storeName}' створено.`);
                } else {
                    console.info(`Об'єктний магазин '${storeName}' вже існує.`);
                }
            });
        };
    });
};
 
// Зберігає дані в об'єктному магазині IndexedDB
    indexedDBConnection.saveData = (storeName, data) => {
    return indexedDBConnection.openDatabase().then((db) => { 
        return new Promise((resolve, reject) => {
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            if(Array.isArray(data)){
                    data.forEach(item => {
                        store.put(item);
                    });
            }else{
                store.put(data);
            }

            transaction.oncomplete = () => {
                // console.info(`Дані успішно збережено в об'єктному магазині '${storeName}'.`);
                resolve();
            };

            transaction.onerror = (event) => {
                console.error('Помилка при збереженні даних в IndexedDB:', {
                    error: event.target.errorCode,
                    message: 'Не вдалося зберегти дані в IndexedDB',
                    storeName: storeName
                });
                reject(event.target.errorCode);
            };
        });
    });
};
    indexedDBConnection.deleteData = (storeName, id) => {
    return indexedDBConnection.openDatabase().then((db) => {
        return new Promise((resolve, reject) => {
            console.log(`Спроба видалити елемент з ID=${id} з магазину '${storeName}'`);
            const transaction = db.transaction([storeName], 'readwrite');
            const store = transaction.objectStore(storeName);
            const request = store.delete(id); // Перетворення ID на число, якщо необхідно

            request.onsuccess = () => {
                console.info(`Елемент з id=${id} успішно вилучено з об'єктного магазину '${storeName}'.`);
                resolve(request.result);
            };

            request.onerror = (event) => {
                console.error('Помилка при вилученні елемента з IndexedDB:', {
                    error: event.target.errorCode,
                    message: 'Не вдалося вилучити елемент з IndexedDB',
                    storeName: storeName,
                    id: id
                });
                reject(event.target.errorCode);
            };
        });
    });
};


// Завантажує дані з об'єктного магазину IndexedDB
indexedDBConnection.loadData = (storeName) => {
    return indexedDBConnection.openDatabase().then((db) => {
        return new Promise((resolve, reject) => {
            const transaction = db.transaction([storeName], 'readonly');
            const store = transaction.objectStore(storeName);
            const request = store.getAll();

            request.onsuccess = () => {
                // console.info(`Дані успішно завантажено з об'єктного магазину '${storeName}'.`);
                resolve(request.result);
            };

            request.onerror = (event) => {
                console.error('Помилка при завантаженні даних з IndexedDB:', {
                    error: event.target.errorCode,
                    message: 'Не вдалося завантажити дані з IndexedDB',
                    storeName: storeName
                });
                reject(event.target.errorCode);
            };
        });
    });
};

// Завантажуємо дані з IndexedDB або серверу в стан
indexedDBConnection.updateStateFromDB = (storeName, apiEndpoint, stateProperty) => {
    indexedDBConnection.loadData(storeName).then((data) => {
        axios.get(apiEndpoint)
            .then((response) => {
                if (response.data[storeName]) {
                    indexedDBConnection.saveData(storeName, response.data[storeName])
                        .then(() => {
                            state[stateProperty] = response.data[storeName]; // Оновлення стану
                        })
                        .catch((error) => {
                            console.error('Помилка при збереженні даних в IndexedDB після запиту:', {
                                error: error,
                                message: 'Не вдалося зберегти дані в IndexedDB після запиту'
                            });
                        });
                }
            })
            .catch((error) => {
                console.error('Помилка запиту до сервера:', {
                    error: error,
                    message: 'Не вдалося отримати дані з сервера'
                });
            });
    });
}

export default indexedDBConnection;
 