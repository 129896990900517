import state from './state';
import axios from 'axios';
import { useState } from 'react';

function FogotPass(props){
    const [eMail, setEMail] = useState('');

    const handleEMailChange = (e) => {
        setEMail(e.target.value.trim());
      };
      function sendPass(){
        axios
        .post(state.api.sendPassToEMail, 
            { eMail: eMail,  })
        .then((response) => {
        
props.setErrorMessage(response.data.message)
setEMail('')
        })

      }

    return(<>
            <div>
              <label>Вкажіть ваш e-Mail:</label><br />
              <input
                type="text"
                value={eMail}
                onChange={handleEMailChange}
               />
            </div>
            <div>
              <button type="button" onClick={()=>{sendPass()}}>Надіслати</button>
              </div>
    </>)
}
export default FogotPass